.MainContainer {
    height: calc(100% - 17px);
    display: flex;
    flex-direction: column;
}

.ShopContainer {
    height: calc(100% - 117px);
    display: flex;
    flex-direction: column;
}

.ShopContainer .CategoriesContainer {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 370px;
    padding-top: 18px;
    position: sticky;
    top: 0;

    background-color: var(--white-bg-color);
    border-right: 1px solid var(--gray-border-color);
}

.ShopContainer .CategoryBoxContainer {
    display: flex;
    gap: 20px;
    flex-direction: row;
    width: 100%;
    padding: 18px 0;
    overflow-y: hidden;
    overflow-x: auto;

    background-color: var(--white-bg-color);
    border-bottom: 2px solid var(--gray-border-color);
}

.CategoriesContainer .SectionTitle,
.ProductsContainer .SectionTitle,
.DetailsContainer .SectionTitle {
    font: normal normal bold 1.25em/1.25em Effra;
    letter-spacing: 0px;
    color: var(--header-text-color);
    margin: 0 42px;
    width: 100%;
}

.CategoriesContainer .CategoryBox {
    width: 100%;
    height: 237px;
    margin: 18px 0;
    padding: 30px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-basis: 318px;
    position: relative;

    background: var(--white-bg-color) 0% 0% no-repeat padding-box;
    border: 1px solid var(--gray-border-color);
    border-radius: 20px;
    cursor: pointer;
}

.CategoriesContainer .CategoryBox:first-child {
    margin-left: 42px;
}

.CategoriesContainer .CategoryBox:hover,
.CategoriesContainer .CategoryBox.Active {
    box-shadow: 0px 3px 16px var(--active-box-shadow-color);
    border: 2px solid var(--active-box-border-color);
    padding: 29px;
}

.CategoriesContainer .CategoryBox .CategoryName {
    font: normal normal normal 1.5em Effra;
    text-align: center;
    color: var(--header-text-color);
    text-transform: uppercase;
}

.CategoriesContainer .CategoryBox:hover .CategoryName,
.CategoriesContainer .CategoryBox.Active .CategoryName {
    font-weight: bold;
}

.CategoriesContainer .CategoryBox .CategoryImage {
    display: flex;
    justify-content: center;
    flex: 1;
    margin: 10px;
    height: 160px;
    overflow: hidden;
}

.CategoriesContainer .CategoryBox .CategoryImage img {
    max-height: 100%;
    align-self: center;
}

.ShopContainer .ProductsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: calc(240px + ((100% - 900px) / 6));
    padding-top: 18px;
    flex: 1;
    overflow: hidden;

    background-color: var(--main-bg-color);
}

.ProductsNotFound {
    display: block;
    margin-top: 20px;

    font: normal normal normal 1.125em/1.5em SF Pro Display;
    color: var(--light-gray-color);
}

.ProductsContainer .ProductBoxesContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 18px calc(42px - ((100% - 900px) / 6));
    overflow-y: auto;
    overflow-x: hidden;
}

.ProductsContainer .ProductBox {
    width: 300px;
    height: 380px;
    margin: calc((100% - 900px) / 6);
    padding: 30px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    position: relative;
    justify-content: space-between;
    text-decoration: none;

    background: var(--white-bg-color) 0% 0% no-repeat padding-box;
    border: 1px solid var(--gray-border-color);
    border-radius: 20px;
    cursor: pointer;
}

.ProductsContainer .ProductBox:hover {
    box-shadow: 0px 3px 16px var(--active-box-shadow-color);
    border: 2px solid var(--active-box-border-color);
    padding: 29px;
}

.ProductsContainer .ProductBox .ProductImage {
    display: flex;
    justify-content: center;
    margin: 5px;
    overflow: hidden;
}

.ProductsContainer .ProductBox .ProductImage img {
    height: 180px;
    align-self: center;
}

.ProductsContainer .ProductBox .ProductName {
    font: normal normal normal 1.875em Effra;
    letter-spacing: 0px;
    color: var(--header-text-color);
    margin-top: 5px;
    padding: 0 5px;
    white-space: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.ProductsContainer .ProductBox:hover .ProductName {
    color: var(--accent-text-color);
}

.ProductsContainer .ProductBox .ProductPrice {
    font: normal normal bold 1.6875em Effra;
    letter-spacing: 0px;
    color: var(--header-text-color);
    margin-top: 10px;
    padding: 5px;
}

.ProductsContainer .ProductBox:hover .ProductPrice {
    color: var(--accent-text-color);
    text-shadow: 0px 3px 16px var(--active-box-shadow-color);
}

.ShopContainer .DetailsContainer {
    display: flex;
    flex-direction: row;
    height: 15em;
    padding: 18px 42px;
    position: sticky;
    bottom: 0;

    background-color: var(--white-bg-color);
    border-top: 2px solid var(--gray-border-color);
}

.DetailsContainer .Info {
    display: flex;
    flex: 0.6;
    flex-direction: row;
    position: relative;
}

.DetailsContainer .Info .ShopImage {
    display: flex;
    justify-content: center;
    padding: 10px;
    width: 190px;
    height: 190px;
    border: 2px solid var(--gray-border-color);
    border-radius: 50%;
    margin-right: 24px;
    overflow: hidden;
}

.DetailsContainer .Info .ShopImage img {
    max-width: 100%;
    align-self: center;
}

.DetailsContainer .Info .InfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
}

.ShopTitle {
    display: flex;
    flex-direction: column;
    gap: 9px;
}

.ShopTitle h6,
.DescriptionContainer h6 {
    letter-spacing: 0px;
    font: normal normal normal 1.875em Effra;
    color: var(--header-text-color);
    margin-bottom: 3px;
}

.ShopTitle span {
    display: flex;
    background: var(--ribbon-bg-color) 0% 0% no-repeat padding-box;
    border-radius: 7px;
    padding: 6px 18px;
    font: normal normal normal 1.0625em Effra;
    letter-spacing: 0px;
    color: var(--ribbon-text-color);
    flex: 0;
    inline-size: min-content;
    white-space: nowrap;
}

.ShopContactInfo {
    display: flex;
    flex-direction: column;
    gap: 9px;
}

.ShopContactField {
    display: flex;
    align-items: center;
    font: normal normal normal 1.25em Effra;
    letter-spacing: 0px;
    color: var(--light-gray-color);
}

.ShopContactField svg {
    font-size: 0.938em;
    line-height: 0.938em;
    margin-right: 5px;
}

.DetailsContainer .DescriptionContainer {
    flex: 0.4;
    position: relative;
}

.DescriptionContainer .Description {
    display: block;
    margin-top: 10px;
    font: normal normal normal 1em Effra;
    height: calc(100% - 46px);
    color: var(--light-gray-color);
    overflow-y: auto;
}

.SpeakerButton {
    padding: 0 !important;
    position: absolute !important;
    right: 10px;
}

.SpeakerButton svg {
    font-size: 1.4rem;
    color: var(--light-gray-color);
}

.CategoryBox:hover .SpeakerButton,
.CategoryBox.Active .SpeakerButton,
.ProductBox:hover .SpeakerButton {
    right: 9px;
}