.MainContainer {
    display: flex;
    flex-direction: column;
}

.MainContainer .CartContainer {
    height: calc(100% - 117px);
    display: flex;
    flex-direction: column;
}

.MainContainer .StepperContainer {
    width: 100%;
    height: 135px;
    padding: 30px 50px;
}

.StepperContainer .StepLabel {
    color: red;
}

.StepContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    margin-bottom: 50px;
    padding: 0 50px;
    overflow-y: auto;
}

.SummaryContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.SummaryContainer .Actions {
    display: flex;
    justify-content: space-between;
    padding: 30px 33px;
    background: var(--white-bg-color) 0% 0% no-repeat padding-box;
    border-radius: 20px;
}

.SummaryContainer .Actions button {
    background: none;
    border: none;
    font: normal normal normal 1.6em Effra;
    color: var(--accent-text-color);
    text-transform: uppercase;
}

.SummaryContainer .Content {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    padding: 50px 20px 50px 50px;
    background: var(--white-bg-color) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    border: var(--product-box-border);
}

.Content .CartContentInfo {
    margin-bottom: 50px;
    width: 100%;
    font: normal normal bold 1.75em Effra;
    color: var(--gray-color-2);
}

.Content .CartContentInfo a {
    font-weight: bold;
    color: var(--accent-text-color);
    text-decoration: none;
}

.Content .Line {
    margin: 15px 0;
    width: 100%;
}

.Content .Position {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Content .Position .Image,
.FinalizeContainer .ProductInfo .Image {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    width: 285px;
    height: 160px;
    padding: 5px;
    margin-left: 35px;
    border: 1px solid var(--gray-color-1);
    border-radius: 10px;
    background: var(--white-bg-color);
}

.Image img {
    max-height: 100%;
    max-width: 100%;
}

.ProductInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.ProductInfo .Name {
    font: normal normal normal 1.8em Effra;
    letter-spacing: 0px;
    color: var(--header-text-color);
}

.ProductInfo .NameWithQuantity {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.ProductInfo .QuantityContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ProductInfo .QuantityContainer .Quantity {
    font: normal normal bold 2.375em Roboto;
}

.ProductInfo .NameWithQuantity .Quantity {
    font: normal normal bold 1.125em/1.375em Effra;
}

.ProductInfo .Quantity input {
    width: 90px;
    margin: 0 10px;
    padding: 22px 0;
    text-align: center;
    border: 1px solid var(--gray-color-1);
    border-radius: 10px;
    font: normal normal normal 0.8em Effra;
    letter-spacing: 0px;
    color: var(--header-text-color);
    background-color: var(--white-bg-color);
}

.ProductInfo .Quantity input:focus-visible {
    outline-color: var(--accent-bg-color);
}

.ProductInfo .Decrase,
.ProductInfo .Incrase {
    color: var(--accent-text-color);
    padding: 0;
}

.ProductInfo .Decrase svg,
.ProductInfo .Incrase svg {
    font-size: 1.9em;
}

.ProductPrice {
    display: flex;
    align-items: center;
    font: normal normal bold 2.2em Roboto;
    color: var(--header-text-color);
}

.QuantityContainer .Remove {
    padding: 0;
}

.Remove svg{
    font-size: 2.5rem;
    color: #df4040;
}

.SidebarContainer {
    display: flex;
    flex-direction: row;
    height: 165px;
    width: 100%;
    position: sticky;
    bottom: 0;
    padding: 0;
}

.SummarySidebarContainer,
.FinalizeSidebarContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px 50px;
    background: var(--white-bg-color) 0% 0% no-repeat padding-box;
    border: var(--product-box-border);
}

.FinalizeSidebarContainer {
    margin: 50px -50px 0 -50px;
    padding-bottom: 50px;
    flex-direction: column;
}

.SummarySidebarContainer span {
    font: normal normal normal 1.5em Effra;
    color: var(--gray-color-2);
}

.SummarySidebarContainer .Price {
    font: normal normal bold 2.8em Roboto;
    letter-spacing: 0px;
    color: var(--main-text-color);
}

.FinalizeSidebarContainer .Price {
    display: flex;
    justify-content: space-between;
    font: normal normal bold 2.375em Roboto;
    padding: 18px 0 13px;
}

.FinalizeSidebarContainer .Price.Discount {
    color: #df4040;
}

.FinalizeSidebarContainer .Price small {
    font: normal normal normal 0.63em Effra;
    color: var(--gray-color-2);
}

.FinalizeSidebarContainer .Price.Summary {
    font: normal normal bold 2.875em Roboto;
    color: var(--accent-text-color);
}

.SummarySidebarContainer .Price small {
    margin-left: 10px;
    font: normal normal bold 0.52em Roboto;
    color: var(--main-text-color);
}

.SummarySidebarContainer button,
.SummarySidebarContainer a,
.FinalizeSidebarContainer button,
.FinalizeSidebarContainer a {
    margin-top: 20px;
}

.FinalizeSidebarContainer .Title {
    font: normal normal bold 1.85em Effra;
    color: var(--header-text-color);
}

.FinalizeContainer {
    width: 100%;
}

.FinalizeContainer .ProductLine {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    border-bottom: 6px solid var(--accent-bg-color);
    margin-bottom: 30px;
}

.FinalizeContainer .ProductLine .Label {
    font: normal normal bold 1.8em Effra;
    color: var(--header-text-color);
    margin: 30px 30px 30px 50px;
}

.FinalizeContainer .ProductLine .MessageInput {
    padding: 35px 50px;
    height: unset;
    width: 100%;
    background: var(--white-bg-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000000D;
    border-radius: 20px;
    border: var(--product-box-border);
    font: normal normal normal 1.8em Effra;
    color: var(--main-text-color);
}

.FinalizeContainer .ProductLine .MessageInput:focus ~ label,
.FinalizeContainer .ProductLine .MessageInput:not(:placeholder-shown) ~ label {
    transform: scale(0.85) translateY(-15px) translateX(0.15rem);
}

.FinalizeContainer .ProductLine .MessageInput:-webkit-autofill ~ label {
    transform: scale(0.85) translateY(-15px) translateX(0.15rem);
}

.FinalizeContainer .ProductLine .MessageInput:focus-visible {
    outline-color: var(--accent-bg-color);
}

.FinalizeContainer .ProductLine .MessageInputLabel {
    font: normal normal normal 1.8em Effra;
    color: var(--gray-color-2);
    padding: 35px 50px;
}

.FinalizeContainer .ProductInfo {
    display: flex;
    flex-direction: row;
    margin-left: 0;
}

.FinalizeContainer .ProductInfo .Image {
    margin-left: 0;
}

.FinalizeContainer .ProductInfo .Name {
    font: normal normal normal 1.8em Effra;
    color: var(--header-text-color);
}

.FinalizeContainer .ProductInfo .Name small {
    font: normal normal bold 0.9em Roboto;
    margin-left: 10px;
}

.FinalizeContainer .ProductInfo .ProductPrice {
    font: normal normal bold 2.5em Roboto;
    color: var(--main-text-color);
}

.FinalizeContainer .ProductInfo .ProductPrice.OldPrice {
    font: normal normal bold 1.125em/1.375em Roboto;
    color: #df4040;
    text-decoration: line-through;
}

.FinalizeContainer .ProductInfo .ProductPrice.NewPrice {
    font: normal normal bold 1.625em/1.75em Roboto;
    color: var(--main-text-color);
}

.FinalizeContainer .ProductInfo .ProductDiscount {
    display: flex;
    flex-direction: column;
    align-items: end;
}


.DeliveryMethodContainer,
.PaymentMethodContainer {
    padding: 50px;
    background: var(--white-bg-color) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    border: var(--product-box-border);
}

.DeliveryMethodContainer .DeliveryMethodBox,
.PaymentMethodContainer .PaymentMethodBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 15px;
    background: var(--white-bg-color) 0% 0% no-repeat padding-box;
    border: 2px solid var(--gray-color-2);
    cursor: pointer;
}

.DeliveryMethodContainer .DeliveryMethodBox {
    height: 312px;
    width: 230px;
    padding: 30px;
    justify-content: space-between;
}

.PaymentMethodContainer .PaymentMethodBox {
    justify-content: space-between;
    width: 230px;
    height: 230px;
    padding: 37px 25px 30px 25px;
}

.DeliveryMethodContainer .DeliveryMethodBox:hover,
.DeliveryMethodContainer .DeliveryMethodBox.Active {
    border: 3px solid var(--accent-bg-color);
    padding: 29px;
}

.PaymentMethodContainer .PaymentMethodBox:hover,
.PaymentMethodContainer .PaymentMethodBox.Active {
    padding: 36px 23px 29px 23px;
    border: 3px solid var(--accent-bg-color);
}

.PaymentMethodBox .PaymentIconContainer {
    height: 105px;
    display: flex;
    align-items: center;
}

.DeliveryMethodBox img,
.PaymentMethodBox img {
    max-width: 100%;
    filter: var(--logo-filter);
}

.PaymentMethodBox img {
    max-height: 105px;
}

.DeliveryMethodBox .Title,
.PaymentMethodBox .Title {
    font: normal normal normal 1.5em Effra;
    color: var(--gray-color-2);
}

.DeliveryMethodBox .Price {
    font: normal normal bold 1.25em/1.438em Effra;
    color: var(--header-text-color);
}

.DeliveryDataContainer {
    display: flex;
    flex-wrap: wrap;
}

.Form {
    padding-bottom: 1px;
}

.DeliveryDataContainer .CardTitle,
.Form .CardTitle {
    padding: 30px 30px 0 0;
}

.PaymentMethodContainer {
    margin-top: 30px;
}


.PaymentMethodContainer .CardTitle {
    padding: 0;
}

.ContinueCart {
    background: var(--accent-bg-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px var(--accent-button-shadow-color);
    border: none;
    border-radius: 20px 0px;
    font: normal normal bold 1.75em Effra;
    text-decoration: none;
    text-transform: uppercase;
    color: var(--accent-button-text);
    padding: 36px 115px;
    margin-top: 0 !important;
}

.ContinueCart:disabled {
    filter: brightness(0.6);
    cursor: no-drop;
}

.OrderAndPay {
    background: var(--accent-bg-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px var(--accent-button-shadow-color);
    border: none;
    border-radius: 0px 20px;
    font: normal normal bold 1.75em Effra;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    color: var(--accent-button-text);
    padding: 36px 30px;
}

.OrderAndPay:disabled {
    filter: brightness(0.6);
    cursor: no-drop;
}

.GUSButton {
    padding: 19px 0;
    width: 100%;
}

.ContinueShopping {
    font: normal normal normal 1.063em/1.25em Effra;
    color: var(--accent-text-color);
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
}

.ContinueShopping:hover {
    color: var(--accent-text-color);
    text-shadow: 0px 3px 20px var(--accent-button-shadow-color);
    font-weight: bold;
}

.DiscardCart {
    background: none;
    border: none;
    font: normal normal normal 1.75em Effra;
    color: #df4040;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    padding: 36px 30px;
}

.DiscardCart:hover {
    color: #df4040;
    text-shadow: 0px 3px 20px #df40404D;
    font-weight: bold;
}

.CardTitle {
    font: normal normal bold 1.9em Effra;
    color: var(--header-text-color);
}

.Form .MessageInputLabel {
    display: flex;
    align-items: center;
    font: normal normal normal 1.5em Effra;
    color: var(--gray-color-2);
    padding: 35px 30px !important;
    top: 8px;
    left: 16px;
}

.Form .MessageInputLabel.DeliveryNotifyMessageInputLabel {
    top: 0;
    left: 0;
}

.Form .MessageInput.Coupon {
    width: 80%;
    border-radius: 20px 0 0 20px !important;
}

.Form .CouponButton {
    background: var(--accent-button-bg) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px var(--accent-button-shadow-color);
    border: none;
    border-radius: 0 20px 20px 0;
    font: normal normal bold 1.5em Effra;
    text-decoration: none;
    text-transform: uppercase;
    color: var(--accent-button-text);
    padding: 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.EmptyCartContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.EmptyCartContainer h2 {
    font: normal normal normal 4.375em/5.625em Effra;
}

.EmptyCartContainer p {
    font: normal normal normal 1.625em/2.25em Roboto;
}

.EmptyCartContainer p a {
    text-decoration: none;
    color: var(--accent-text-color);
    font-weight: bold;
}

.SidebarContainer .SummaryPriceContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}