.MainContainer {
    height: 100%;
}

.Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100% - 100px);
    padding: 30px 50px;
    position: relative;
}

.Container::before {
    background: var(--main-bg-color) url('../../images/bg/thanks.png') 90% 0% no-repeat padding-box;
    background-size: contain;
    background-position: right -425px bottom 0;
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    filter: var(--background-image-filter);
    z-index: 1;
}

.Container .Message {
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 2;
}

.Container .Message h1 {
    font: normal normal normal 3.125em Effra;
    color: var(--header-text-color);
    margin-bottom: 10px;
}

.Container .Message h3 {
    font: normal normal bold 1.875em Effra;
    color: var(--header-text-color);
    margin: 0;
}

.Container .Message p {
    margin: 30px 0 0 0;
    font: normal normal normal 1.5em Effra;
    color: var(--header-text-color);
}