.MainContainer {
    display: flex;
    flex-direction: column;
}

.ProductContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
}

.ProductDetailsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 165px);
    overflow: hidden;
    background-color: var(--main-bg-color);
}

.ProductImageContainer {
    height: 490px;
    padding: 0 50px;
    background-color: var(--main-bg-color);
}

.ProductImage {
    display: flex;
    justify-content: center;
    padding: 30px;
    max-width: 100%;
    height: 100%;

    background: var(--white-bg-color) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    border: var(--product-box-border);
}

.ProductImage img {
    max-width: 100%;
    max-height: 450px;
    align-self: center;
}

.ProductInfoContainer {
    padding: 0 50px;
    background-color: var(--main-bg-color);
}

.ProductInfoContainer .Container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 50px;
    width: 100%;
    height: 100%;
    background: var(--white-bg-color) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    border: var(--product-box-border);
}

.ProductInfo {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.ProductInfo .Name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: normal normal bold 2.125em Effra;
    letter-spacing: 0px;
    color: var(--header-text-color);
    padding-right: 16px;
}

.ProductInfo .Name .ShopNameWithPictogram {
    display: flex;
    align-items: center;
    gap: 5px;
    font: normal normal normal 1.5rem Effra;
    color: var(--accent-text-color);
}

.ProductInfo .Name .ShopNameWithPictogram img {
    width: 30px;
    height: 30px;
    filter: var(--logo-filter);
}

.ProductInfo .Price {
    display: flex;
    align-items: center;
    margin-top: 15px;
    font: normal normal bold 2.375em Roboto;
    letter-spacing: 0px;
    color: var(--header-text-color);
}

.ProductInfo .Quantity {
    margin-left: 110px;
}

.ProductInfo .Quantity input {
    width: 90px;
    margin: 0 10px;
    padding: 22px 0;
    text-align: center;
    border: 1px solid var(--gray-color-1);
    border-radius: 10px;
    font: normal normal normal 0.8em Effra;
    letter-spacing: 0px;
    color: var(--header-text-color);
    background-color: var(--white-bg-color);
}

.ProductInfo .Decrase,
.ProductInfo .Incrase {
    color: var(--accent-text-color);
    padding: 0;
}

.ProductInfo .Decrase svg,
.ProductInfo .Incrase svg {
    font-size: 1.9em;
}

.ProductVariants {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0 30px 0;
    margin-top: 26px;
    border-top: 2px solid var(--gray-border-color);
    border-bottom: 2px solid var(--gray-border-color);
}

.ProductVariants a {
    padding: 22px 42px;
    margin-top: 10px;
    margin-right: 11px;
    border: 1px solid var(--gray-color-1);
    border-radius: 10px;
    color: var(--gray-color-2);
    font: normal normal normal 1.5em Effra;
    text-decoration: none;
}

.ProductVariants a.Active {
    border-color: var(--active-box-border-color);
    color: var(--accent-text-color);
    box-shadow: var(--active-variant-shadow);
}

.ProductDescription {
    font: normal normal normal 1.625em Effra;
    color: var(--header-text-color);
}

.ProductDescription h1 {
    margin: 30px 0;
    font: normal normal bold 1.875em Effra;
    color: var(--header-text-color);
}

.AddToCartContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 165px;
    width: 100%;
    padding: 0 50px;
    background: var(--white-bg-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px -6px 16px var(--shadow-color);
    position: absolute;
    bottom: 0;
}

.AddToCartContainer .RightSide {
    display: flex;
    flex-direction: row;
}

.AddToCartContainer .Summary {
 display: flex;
 flex-direction: column;
 align-items: center;
}

.AddToCartContainer .Summary .Title {
    font: normal normal normal 1.5em Effra;
    color: var(--gray-color-2);
}

.AddToCartContainer .Summary .Price {
    font: normal normal bold 2.8em Roboto;
    color: var(--header-text-color);
}

.AddToCartContainer .AddToCart {
    margin-left: 18px;
    padding: 36px 125px;
    background: var(--accent-button-bg) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px var(--accent-button-shadow-color);
    border-radius: 12px 0px;
    font: normal normal bold 1.75em Effra;
    color: var(--white-text-color);
}

.AddToCartContainer .GoBack {
    margin-left: 18px;
    padding: 22px 76px;
    background: transparent 0% 0% no-repeat padding-box;
    border: 2px solid var(--accent-button-bg);
    box-shadow: 0px 3px 20px var(--accent-button-shadow-color);
    border-radius: 12px 0px;
    font: normal normal bold 1.063em/1.25em Effra;
    color: var(--accent-button-bg);
}

.AddToCartContainer .AddToCart:hover {
    background: var(--accent-button-bg) 0% 0% no-repeat padding-box;
    filter: brightness(0.9);
}

.ProductInfoContainer .SpeakerButton {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 0;
}

.ProductInfoContainer .SpeakerButton svg {
    font-size: 1.2rem;
    color: var(--light-gray-color);
}

.ConfirmDialog .MuiPaper-root {
    max-width: 710px;
}

.ConfirmDialog .Title {
    font: normal normal normal 1.875em Effra;
    color: var(--header-text-color);
    text-align: center;
}

.ConfirmDialog .ProductBox {
    width: 300px;
    min-height: 385px;
    margin: 50px 0;
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    text-decoration: none;

    background: var(--white-bg-color) 0% 0% no-repeat padding-box;
    border: 1px solid var(--gray-border-color);
    border-radius: 20px;
}

.ConfirmDialog .ProductBox .ProductImage {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 5px;
    width: 200px;
    border: none;
}

.ConfirmDialog .ProductBox .ProductImage img {
    width: 100%;
    max-height: 100%;
    align-self: center;
}

.ConfirmDialog .ProductBox .ProductName {
    font: normal normal normal 1.8em Effra;
    letter-spacing: 0px;
    color: var(--header-text-color);
    margin-top: 15px;
}

.ConfirmDialog .ProductBox .ProductPrice {
    font: normal normal bold 1.7em Effra;
    letter-spacing: 0px;
    color: var(--header-text-color);
}

.ConfirmDialog .Content {
    padding-bottom: 0;
}

.ConfirmDialog .Actions {
    display: flex;
    flex-direction: column;
    padding: 0;
}

.ConfirmDialog .TakeOrder {
    background: var(--accent-button-bg) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px var(--accent-button-shadow-color);
    border-radius: 20px 0px;
    font: normal normal bold 1.75em Effra;
    text-decoration: none;
    color: var(--accent-button-text);
    padding: 36px 50px;
}

.ConfirmDialog .ContinueShopping {
    font: normal normal bold 1.75em Effra;
    color: var(--light-gray-color);
    margin: 75px 0;
    cursor: pointer;
}