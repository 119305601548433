.TabsContainer {
    background: var(--main-bg-color);
}

.TabContainer {
    height: calc(100% - 60px);
    overflow-x: auto;
    margin-top: 20px;
    position: relative;
}

.TabContainer::before {
    background: url('../../images/bg/categories.png?filter=grayscale(100%)') 0% 0% no-repeat padding-box;
    background-size: 2353px 659px;
    background-position: bottom;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: var(--background-image-filter);
    z-index: 1;
}

.TabButtonsContainer {
    max-width: 50em;
}

.TabsContainer .TabButton {
    display: block;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    width: 100%;
    background: var(--main-button-bg) 0% 0% no-repeat padding-box;
    font: normal normal normal 2.125em Effra;
    letter-spacing: 0px;
    color: var(--main-button-text);
    padding: 32px 0px;
}

.TabsContainer .TabButton:hover {
    background-color: var(--main-button-bg);
    filter: brightness(0.9);
}

.TabsContainer .TabButtonLeft {
    border-radius: 10px 0px 0px 0px;
}

.TabsContainer .TabButtonRight {
    border-radius: 0px 0px 10px 0px;
}

.TabsContainer .TabButtonActive {
    background: var(--accent-button-bg) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    color: var(--accent-button-text);
    font-weight: bold;
}

.TabsContainer .TabButtonActive:hover {
    background-color: var(--accent-button-bg);
    filter: brightness(0.9);
}

.CategoryBoxContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 calc((100% - 780px) / 6);
    position: relative;
    z-index: 2;
}

.CategoryBox {
    display: flex;
    flex-direction: column;
    height: 260px;
    width: 260px;
    margin: 20px calc((100% - 780px) / 6);
    padding: 10px;
    background-color: var(--white-bg-color);
    border: 1px solid var(--gray-border-color);
    box-shadow: 0px 3px 12px var(--shadow-color);
    border-radius: 20px;
}

.SpeakerButton {
    padding: 0;
}

.SpeakerButton svg {
    font-size: 1.4rem;
    color: var(--light-gray-color);
}

.CategoryName {
    font: normal normal normal 2.125em Effra;
    text-align: center;
    color: var(--main-text-color);
}

.CategoryImage {
    display: flex;
    justify-content: center;;
    flex: 1;
    overflow: hidden;
    margin: 10px;
}

.CategoryBox img {
    max-height: 100%;
    align-self: center;
    filter: var(--logo-filter);
}

.ShopBoxContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 calc((100% - 1000px) / 4);
    position: relative;
    z-index: 2;
}

.ShopBox {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 175px;
    width: 500px;
    margin: 20px calc((100% - 1000px) / 4);
    background-color: var(--white-bg-color);
    border: 1px solid var(--gray-border-color);
    box-shadow: 0px 3px 12px var(--shadow-color);
    border-radius: 20px;
    text-decoration: none;
}

.ShopBox .SpeakerButton {
    position: absolute;
    right: 0;
}

.CategoryBox:hover,
.ShopBox:hover {
    cursor: pointer;
    box-shadow: 0px 3px 12px var(--shadow-hover-color);
}

.ShopLogo {
    display: flex;
    justify-content: center;
    margin: 10px;
    width: 165px;
    overflow: hidden;
}

.ShopLogo img {
    max-width: 100%;
    max-height: 100%;
    align-self: center;
    filter: var(--logo-filter);
}

.ShopInfo {
    width: 335px;
    padding: 17px 26px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 1px solid #D1D1D1;
}

.ShopTitle h6 {
    letter-spacing: 0px;
    font: normal normal 400 1.875em Effra;
    color: var(--main-text-color);
    margin-bottom: 3px;
}

.ShopTitle span {
    background: var(--ribbon-bg-color) 0% 0% no-repeat padding-box;
    border-radius: 7px;
    padding: 5px 18px;
    font: normal normal normal 1.0625em Effra;
    letter-spacing: 0px;
    color: var(--ribbon-text-color);
}

.ShopPhoneNumber {
    display: flex;
    align-items: center;
    font: normal normal normal 1.25em SF Pro Display;
    letter-spacing: 0px;
    color: var(--light-gray-color);
}

.ShopPhoneNumber svg {
    font-size: 0.938em;
    line-height: 0.938em;
    margin-right: 5px;
}