html, body, #root {
  height: 100%;
}

/* Provide sufficient contrast against white background */
body {
  color: #434343;
  background-color: var(--main-bg-color);
  font-family: SF Pro Display;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: SF Pro Display !important;
}
a {
  color: #0366d6;
  cursor: pointer;
}

code {
  color: #E01A76;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: var(--scroll-bar-track);
  border-radius: 50px;
  margin: 10px 0;
}

::-webkit-scrollbar-thumb {
  background: var(--scroll-bar-thumb);
  border-radius: 50px;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

/*
  Logo
*/
.logo-small {
  background: url('images/logo_small.png') no-repeat;
  background-size: 100%;
  width: 165px;
  height: 90px;
  float: right;
  margin-right: 10px;
  display: flex;
  align-self: center;
}

/*
  Layout
*/
.app-container {
  min-height: 350px;
}

/*
  Sizing
*/
.height100 {
  height: 100%;
}

/*
  MUI
*/

.Mui-focused fieldset {
  border-color: #09cab3 !important;
}

label.Mui-focused {
  color: #09cab3 !important;
}

.big-checkbox-label .MuiTypography-root {
  font-size: 1.5rem;
}

.MuiCheckbox-colorPrimary svg {
  width: 38px;
  height: 38px;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: var(--accent-text-color) !important;
}

.MuiCheckbox-colorPrimary {
  color: var(--header-text-color) !important;
}

/*
  Tables
*/
.table-menu {
  align-items: center;
  border-bottom: 1px solid #09cab3;
  padding-bottom: 15px;
  margin-bottom: 10px !important;
}

  .table-menu h1 {
      padding: 0;
      margin: 0;
      font: normal normal normal 1.25em SF Pro Display;
      letter-spacing: 0;
  }

.MuiTable-root {
  border-collapse: separate !important;
  border-spacing: 0 15px !important;
}

.MuiTableCell-root {
  border-bottom: none !important;
  padding-left: 5px !important;
  font-family: SF Pro Display !important;
}

.MuiTableCell-head {
  color: #bcbcbc !important;
  font-weight: bold;
}

.MuiTableRow-root {
  height: 50px;
  margin-bottom: 5px;
}

.table-value-cell {
  background-color: white;
}

.table-value-cell {
  font-size: 1.125em !important;
}

.table-value-cell.first {
  border-radius: 5px 0 0 5px;
  padding-left: 20px !important;
}

.table-value-cell.last {
  border-radius: 0 5px 5px 0;
  padding-right: 20px !important;
}

.table-head-cell.first {
  padding-left: 20px !important;
}

.table-head-cell.last {
  padding-right: 20px !important;
}

.checkbox-cell {
  padding-right: 10px !important;
}
/*
  Bootstrap
*/
.btn-link {
  text-decoration: none;
  color: #09cab3 !important;
  font: normal normal bold 1em SF Pro Display !important;
  letter-spacing: 0px;
  text-transform: none !important;
}

.btn-link:hover {
  color: rgba(9, 202, 179, 0.8);
}

/*
  Buttons
*/
.button {
  padding: 20px 0;
  border: none;
  border-radius: 5px;
  font: normal normal bold 0.875em SF Pro Display;
  text-transform: none !important;
}

  .button:hover {
      opacity: 0.8;
  }

.filled-button {
  background: #09CAB3 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 10px #10101033;
  color: white !important;
}

.outline-button {
  background: white 0% 0% no-repeat padding-box !important;
  color: #09CAB3 !important;
}

.breadcrump {
  position: relative;
  background: var(--accent-bg-color) 0% 0% no-repeat padding-box;
  font: normal normal bold 1.875em/2.25em Effra;
  letter-spacing: 0px;
  color: var(--white-text-color);
  padding: 22px 55px;
}

.breadcrump a {
  color: var(--white-text-color);
  text-decoration: none;
}

.breadcrump a:hover {
  text-decoration: underline;
}

.breadcrump .last-item {
  font-weight: bold;
}

.breadcrump button {
  margin-left: -25px;
}

.breadcrump button svg {
  width: 36px;
  height: 36px;
  color: var(--white-text-color);
}

.pr-1 {
  padding-right: 10px !important;
}

.pr-2 {
  padding-right: 20px !important;
}

.pl-1 {
  padding-left: 10px !important;
}

.MuiButtonBase-root .MuiButton-label {
  color: var(--accent-text-color) !important;
}

.confirm-box__actions button {
  border-width: 1px;
}

.confirm-box__actions button:first-child {
  background: #D50000;
  color: var(--white-text-color);
}

.MuiModal-root .MuiDialog-paper,
.MuiDialog-root .MuiDialog-paper {
  background-color: var(--white-bg-color);
  border: var(--product-box-border);
}

.MuiDialog-root .MuiDialog-paper .MuiDialogContent-root  .MuiTypography-root {
  font-size: 1.6em;
}

.MuiDialog-root .MuiDialog-paper .MuiDialogActions-root {
  padding: 8px 24px;
  justify-content: space-between;
}

.MuiDialog-root .MuiDialog-paper .MuiDialogActions-root .MuiButtonBase-root {
  font-size: 1.3em;
}

.MuiDialog-root .MuiTypography-root {
  color: var(--main-text-color);
}

.bp-text-button {
  font: normal normal bold 1.5em Effra;
  text-decoration: none;
  color: var(--accent-text-color);
}