.FormField {
    padding: 35px 30px !important;
    height: unset !important;
    width: 100%;
    background: var(--white-bg-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000000D;
    border-radius: 20px;
    border: var(--product-box-border);
    font: normal normal normal 1.5rem Effra;
    color: var(--main-text-color) !important;
}

.FormField:focus ~ label,
.FormField:not(:placeholder-shown) ~ label {
    transform: scale(0.85) translateY(-20px) translateX(0.15rem) !important;
    opacity: 1;
}

.FormField:-webkit-autofill ~ label {
    transform: scale(0.85) translateY(-20px) translateX(0.15rem) !important;
    opacity: 1;
}

.FormField:focus {
    box-shadow: none;
    background-color: var(--white-bg-color);
    border: 2px solid var(--accent-bg-color);
    padding: 34px 29px !important;
}

.FormField:disabled {
    background-color: var(--white-bg-color);
}

.FormFieldLabel {
    font: normal normal normal 1.9em Effra;
    color: var(--gray-color-2);
    padding-left: 30px;
    top: 16px;
    left: 16px;
}