.MainContainer {
    height: 100%;
}

.Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100% - 100px);
    padding: 30px 50px;
    position: relative;
}

.Container::before {
    background: var(--main-bg-color) url('../../images/bg/thanks.png') 90% 0% no-repeat padding-box;
    background-size: contain;
    background-position: right -425px bottom 0;
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    filter: var(--background-image-filter);
    z-index: 1;
}

.Container .Message {
    z-index: 2;
}

.Container .Message h1 {
    font: normal normal normal 3.125em Effra;
    color: var(--header-text-color);
    margin-bottom: 10px;
}

.Container .Message h3 {
    font: normal normal bold 1.875em Effra;
    color: var(--header-text-color);
    margin: 0;
}

.Container .Message p {
    margin: 30px 0 0 0;
    font: normal normal normal 1.5em Effra;
    color: var(--header-text-color);
}

.Container .NotificationsContainer {
    display: flex;
    margin-top: 100px;
    z-index: 2;
}

.Container .Notifications {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0.6;
    padding-right: 50px;
    border-right: 1px solid #B5B5B5;
}

.Container .Notifications p,
.Container .TrackOrder p {
    font: normal normal bold 1.875em Effra;
    color: var(--header-text-color);
}

.Container .Notifications .MessageInput {
    padding: 35px 50px;
    height: unset;
    width: 100%;
    background: var(--white-bg-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000000D;
    border: var(--product-box-border);
    border-radius: 20px;
    font: normal normal normal 1.875em Effra;
}

.Container .Notifications .MessageInput:focus ~ label,
.Container .Notifications .MessageInput:not(:placeholder-shown) ~ label {
    transform: scale(0.85) translateY(-15px) translateX(0.15rem);
}

.Container .Notifications .MessageInput:-webkit-autofill ~ label {
    transform: scale(0.85) translateY(-15px) translateX(0.15rem);
}

.Container .Notifications .MessageInput:focus-visible {
    outline-color: var(--accent-bg-color);
}

.Container .Notifications .MessageInputLabel {
    font: normal normal normal 1.875em Effra;
    color: var(--gray-color-2);
    padding: 35px 50px !important;
}

.Container .TrackOrder {
    flex: 0.4;
    padding-left: 50px;
}

.Container .ButtonContainer {
    display: flex;
    margin: 115px 0 0 0;
    z-index: 2;
}

.Container .Button {
    background: var(--accent-button-bg) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px var(--accent-button-shadow-color);
    border: none;
    border-radius: 20px 0px;
    font: normal normal bold 1.75em Effra;
    text-decoration: none;
    text-transform: uppercase;
    color: var(--accent-button-text);
    padding: 36px 117px;
}