a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.LogoSmall {
  background: url('../../images/logo_small.png') no-repeat;
  background-size: 100%;
  width: 165px;
  height: 60px;
  float: right;
  margin-right: 10px;
  display: flex;
  align-self: center;
  filter: var(--logo-filter);
}

.header-main {
  background-color: var(--main-bg-color);
  border-bottom: 1px solid #D1D1D1 !important;
}

.header-main .circle-buttons-container {
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #D1D1D1 !important;
  border-right: 1px solid #D1D1D1 !important;
  padding: 0 54px;
}

.circle-buttons-container .locale-buttons {
  display: flex;
}

.cart-button-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #D1D1D1 !important;
}

.header-main .circle-button {
  margin: 6px;
  border: 2px solid #D1D1D1;
  border-radius: 50%;
  padding: 3px;
  color: #D1D1D1;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.header-main .circle-button.with-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.header-main .black-border .circle-button {
  border-color: #000000;
}

.header-main .circle-button.colors-1,
.header-main .circle-button.colors-1:hover {
  box-shadow:inset 0px 0px 0px 2px #000000;
  color: #000000;
  background-color: #FFFFFF;
}

.header-main .circle-button.colors-2,
.header-main .circle-button.colors-2:hover {
  background-color: #000000;
  color: #ffffff;
}

.header-main .circle-button.colors-3,
.header-main .circle-button.colors-3:hover {
  background-color: #FFFF00;
  color: #000000;
}

.header-main .circle-button.colors-4,
.header-main .circle-button.colors-4:hover {
  background-color: #000000;
  color: #FFFF00;
}

.header-main .circle-button.with-text .small {
  font-size: 16px;
}

.header-main .circle-button.with-text .medium {
  font-size: 20px;
}

.header-main .circle-button.with-text .big {
  font-size: 24px;
}

.header-main .circle-button:hover,
.header-main .circle-button.active {
  border-color: #09CAB3;
  color: #09CAB3;
}

.logo-container {
  padding: 45px;
  border-bottom: 1px solid #D1D1D1 !important;
  border-right: 1px solid #D1D1D1 !important;
}

.logo-container img {
  filter: var(--logo-filter);
  max-width: 210px;
}

.main-search-box {
  height: 100%;
  border-left: 1px solid #D1D1D1 !important;
  border-right: 1px solid #D1D1D1 !important;
  margin: 0 !important;
}

.main-search-box .MuiInputBase-root {
  height: 100%;
  padding: 0 30px;
}

.main-search-box input {
  /* font: normal normal normal 2rem/2.4375rem SF Pro Display; */
  font: normal normal normal 1.875em/2.25em Effra;
  color: var(--main-text-color);
  padding: 42px 14px;
}

.main-search-box fieldset {
  border: none;
}

.main-search-box legend {
  display: none;
}

.main-search-box .search-icon {
    font-size: 3.2rem;
    color: var(--accent-text-color);
}

