.SearchContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 66px 42px;
}

.Shop {
    display: flex;
    flex-direction: column;
}

.Shop h1 {
    margin-bottom: 20px;
    font: normal normal bold 1.875em/2.25em Effra;
    letter-spacing: 0px;
    color: #202020;
}

.ProductsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.Product {
    display: flex;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 20px;
    text-decoration: none;
}

.Product:hover,
.ProductActive {
    box-shadow: 0px 3px 16px #09cab379;
    border: 2px solid #09CAB3;
}

.ShopLogo {
    display: flex;
    justify-content: center;
    padding: 10px;
    width: 330px;
    border-right: 1px solid #D1D1D1;
}

.Product:hover .ShopLogo,
.ProductActive .ShopLogo {
    padding-left: 9px;
}

.ShopLogo img {
    max-width: 100%;
    align-self: center;
}

.ProductInfo {
    padding: 18px 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Product:hover .ProductInfo,
.ProductActive .ProductInfo {
    padding: 17px 28px;
}

.Product .ProductInfoContainer {
    flex: 0.5;
}

.ProductImage {
    display: flex;
    flex: 0.5;
    justify-content: center;
    padding: 31px;
}

.ProductImage img {
    max-width: 100%;
    align-self: center;
}

.ProductName {
    font: normal normal normal 1.6em Effra;
    letter-spacing: 0px;
    color: #202020;
    margin-top: 5px;
    margin-bottom: 0;
    padding: 5px;
}

.Product:hover .ProductName,
.ProductActive .ProductName {
    color: #09CAB3;
}

.ProductPrice {
    font: normal normal bold 1.5em/1.625em Effra;
    letter-spacing: 0px;
    color: #202020;
    padding: 0 5px 5px 5px;
}

.Product:hover .ProductPrice,
.ProductActive .ProductPrice {
    color: #09CAB3;
    text-shadow: 0px 3px 16px #09cab379;
}