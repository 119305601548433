.LoginBackground {
    background: url('../../images/background.png') no-repeat;
    background-size: 100%;
    width: 100%;
    height: 100vh;
}
  
.LoginContainer {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    padding: 250px 160px !important;
    background: #F0F2F4 0% 0% no-repeat padding-box;
}

.LoginContainer::before {
    background: url('../../images/bg/categories.png') 0% 0% no-repeat padding-box;
    background-size: 2353px 659px;
    background-position: bottom;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: var(--background-image-filter);
    z-index: 1;
}

.LogoutContainer {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 !important;
}

.LogoSmall {
    background: url('../../images/logo_small.png') no-repeat;
    background-size: 100%;
    width: 165px;
    height: 90px;
    float: right;
    margin-right: 10px;
    display: flex;
    align-self: center;
}
  
.LoginForm {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    gap: 30px;
    position: relative;
    z-index: 2;
}
  
.LoginForm h1 {
    font-size: 1.875em;
    font-weight: 400;
}

.LogoutForm {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 50px 110px;
}
  
.LogoutForm h1 {
    font: normal normal bold 1.875em Effra;
    margin: 0 0 0 50px;
}

.LoginButton {
    background-color: #09cab3;
    color: white !important;
    width: 100%;
    border: none;
    border-radius: 20px 0px;
    padding: 36px 0;
    font-size: 1.75em;
    font-weight: bold;
    box-shadow: 0px 3px 6px #0000000F;
}
  
.LoginButton:hover {
    background-color: rgba(9, 202, 179, .8);
}

.MessageInputLabel {
    display: flex;
    align-items: center;
    font: normal normal normal 1.5em Effra;
    color: var(--gray-color-2);
    padding: 35px 30px !important;
    top: 8px;
    left: 16px;
}
  